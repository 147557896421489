<template>
<div>
    <!-- <v-row v-show="$vuetify.breakpoint.mobile"></v-row>
    <v-row style="margin-top: 0%" v-show="!$vuetify.breakpoint.mobile"></v-row> -->
    <waiting-modal :title="title" :status="status" :active="active_waiting" @statusdailog="update_status_wait"></waiting-modal>
    <!-- {{date}} -->
    <v-container>
        <v-row class="align-center d-flex h-0 pb-20">
            <v-col xl="3" lg="4" sm="8" class="mx-auto">
                <br />
                <v-row class="d-flex">
                    <v-col col="12" style="text-align: center">
                        <span class="text-purple" style="font-weight: 600; font-size: 20px">เพิ่มการนัดพบแพทย์</span>
                    </v-col>
                </v-row>
                <br />
                <!-- @click:append=on  -->
                <v-row class="d-flex">
                    <v-col col="12">
                        <v-card style="border-radius: 15px" elevation="1">
                            <v-card-text>
                                <v-form ref="form" v-model="valid" lazy-validation>
                                    <v-autocomplete clearable @click="checkProviderList" v-model="provider_list_id" :rules="[(v) => !!v || 'โปรดเลือก']" :item-value="'id'" :item-text="'provider_name_location'" :items="provider_list" outlined required dense label="คลินิก/สถานพยาบาล"></v-autocomplete>
                                    <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" width="290px" :close-on-content-click="true">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="date" readonly :rules="[(v) => !!v || 'โปรดเลือก']" label="เลือกวันที่นัด" v-on:click:append="modal=true" append-icon="calendar_month" color="bg-purple" dense outlined required  v-on="on"></v-text-field>
                                        </template>

                                        <v-date-picker v-model="date" @input="showPicker = false" event-color="bg-teal" locale="th-th" color="bg-purple" no-title :min="
                                            new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)" @change="$refs.dialog.save(date)"></v-date-picker>

                                    </v-dialog>

                                    <v-select :items="[
                        '08:00',
                        '09:00',
                        '10:00',
                        '11:00',
                        '13:00',
                        '14:00',
                        '15:00',
                        '16:00',
                        '17:00',
                        '18:00',
                      ]" :rules="[(v) => !!v || 'โปรดเลือก']" v-model="booking_time" label="เวลา" dense outlined required></v-select>
                                    <v-textarea v-model="detail" outlined name="input-7-4" label="รายละเอียด" value="" ></v-textarea>
                                </v-form>
                            </v-card-text>
                        </v-card>
                        <br>
                        <span style="color:#A84192 !important;">***กรุณายืนยันทางโทรศัพท์กับคลินิกอีกครั้ง</span>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row v-show="!$vuetify.breakpoint.mobile" class="align-center" justify="center" style="padding-top: 15px">
            <v-btn @click="$router.go(-1)" outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl">
                <v-icon small>west</v-icon>
            </v-btn>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <v-btn @click="validate" style="width: 250px" color="bg-primary text-white" class="border-radius-xl">
                ยืนยันข้อมูล
            </v-btn>
        </v-row>

        <v-footer v-show="$vuetify.breakpoint.mobile" fixed class="action-nav" style="height: 60px">
            <v-row class="align-center" justify="center">
                <v-btn @click="$router.go(-1)" outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl">
                    <v-icon small>west</v-icon>
                </v-btn>
                &nbsp; &nbsp; &nbsp; &nbsp;
                <v-btn @click="validate" style="width: 250px" color="bg-primary text-white" class="border-radius-xl">
                    ยืนยันข้อมูล
                </v-btn>
            </v-row>
        </v-footer>
    </v-container>
</div>
</template>

<script>
import CheckmongHomeBar from "@/components/CheckmongHomeBar.vue";
import {
    APIURL
} from '@/constants'
import Vue from 'vue'
import WaitingModal from "@/components/WaitingModal.vue";
export default {
    name: "calendar-patient",
    components: {
        CheckmongHomeBar,
        WaitingModal
    },

    data: () => ({

        modal: false,
        showPicker: false,
        active_waiting: false,
        title: '',
        status: '',
        provider_list_id: 1,
        provider_list: [],
        activePicker: null,
        date: null,
        booking_time: '',
        detail: '',
        menu: false,
        selected_row: "",
        dialog: false,
        arrayEvents: null,
        date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        valid: true,
        name: "",
        nameRules: [
            (v) => !!v || "โปรดกรอกข้อมูล",
            (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    }),

    mounted() {},
    watch: {
        date(val) {
            //console.log('picked date')
            this.date = val
            // this.modal = false
        }
    },
    methods: {
        toggledate() {
            //console.log('toggledate')

        },
        update_status_wait(val) {
            this.active_waiting = val
        },
        async submitVal() {

            this.dialog_new = false
            this.active_waiting = true
            this.title = 'กำลังเพิ่มข้อมูล',
                this.status = 'loading'
            await Vue.axios.post(APIURL + '/add_booking_record', {
                "user_id": this.$store.state.login_detail.user_id,
                "provider_list_id": this.provider_list_id,
                "booking_date": this.date,
                "booking_time": this.booking_time,
                "status": "",
                "detail": this.detail,
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                if (response.data) {
                    this.title = 'เพิ่มข้อมูลสำเร็จ';
                    this.status = 'done'
                    setTimeout(() => {
                        this.active_waiting = false
                    }, 2000)
                    // this.get_alert_record()
                    this.$refs.form.reset()
                    this.$router.push('/book_doctor_list')
                } else {
                    this.active_waiting = false
                    this.title = 'ระบบขัดข้อง',
                        this.status = 'fail'
                }

            })

        },
        checkProviderList() {
            //console.log(this.provider_list)
            //console.log(this.provider_list.length)

            if (this.provider_list) {
                //console.log('getProviderList')
                this.getProviderList()
            }
        },
        async getProviderList() {
            this.active_waiting = true
            this.title = '',
                this.status = 'loading'
            await Vue.axios.post(APIURL + '/get_provider_list', {}, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                if (response.data) {
                    this.active_waiting = false
                    //console.log(response.data)
                    this.provider_list = response.data.provider_list;
                    this.provider_list = this.provider_list.filter(provider => !provider.type.indexOf("clinic"))
                    this.provider_list = this.provider_list.filter(provider => !provider.is_active == 0)
                    //console.log('777777777777777')
                    //console.log(this.provider_list)


                } else {
                    this.active_waiting = false
                    //console.log(response)
                }

            })

        },
        save(date) {
            this.$refs.menu.save(date);
        },
        validate() {
            const res = this.$refs.form.validate();
            if (res) {
                // this.$router.push('/book_doctor_list')
                this.submitVal()
            }
        },
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
    },
};
</script>

<style scoped>
.v-card {
    display: flex !important;
    flex-direction: column;
}

.v-card__text {
    flex-grow: 1;
    overflow: auto;
}

.action-nav {
    background: #ffffff !important;
    box-shadow: 0px -2px 4px rgba(207, 202, 202, 0.25) !important;
    border-top-left-radius: 18px !important;
    border-top-right-radius: 18px !important;
    height: 77 px !important;
}

.v-menu__content .v-list-item__title {
    font-size: 0.875rem;
    color: #ffffff;
    color: gray;
}

.custom-field {
    border-radius: 6px;
}
.v-text-field>.v-input__control>.v-input__slot>.v-text-field__slot {
    padding-left: 0px !important;
}
.v-picker__title {
    color: #ffffff;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    padding: 16px;
    background-color: #a84192;
}

.v-main__wrap {
    padding-bottom: 90px;
}

.position-relative {
    position: relative;
}

.v-date-picker-table .v-btn.v-btn--active {
    color: #ffffff;
    background-color: #a84192;
}

.theme--light.v-date-picker-header .v-date-picker-header__value:not(.v-date-picker-header__value--disabled) button:not(:hover):not(:focus) {
    color: #a84192 !important;
}

.theme--light.v-btn.v-btn--icon {
    color: #a84192 !important;
}

.v-dialog>.v-card>.v-card__text {
    padding: 0 24px 0px;
}
</style>
